<template>
  <div class="text-center">
    <v-dialog
      v-model="InfoAlert"
      persistent
      max-width="300"
    >
      <v-card class="d-flex align-center justify-center flex-column">
          <v-card-title
           class="d-flex justify-space-between align-center w-100 primary white--text px-6 py-3"
        >
          <h5>    {{ InfoAlertTitle }}</h5>
         
        </v-card-title>
        
     <v-card-text class="d-flex align-start px-6 py-6">
         
          
          <v-card-title style="word-break: normal" class="text-subtitle-1 pa-0">
          {{ InfoAlertMessage }}
          </v-card-title>

         
        </v-card-text>
       


        <v-card-actions class="d-flex justify-end align-center w-100 pb-4 pt-0 px-6">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="emitInfoAlertOkClicked"
          >
            {{$t('infoAlert.ok')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name : 'InfoAlert',
  data() {
    return {
      emitMsg : 'click Success'
    }
  },
  props:{
    InfoAlert : {
      type: Boolean,
      default: false
    },
    InfoAlertTitle : {
      type: String,
      default: 'Info...'
    },
    InfoAlertMessage : {
      type: String,
      default: 'Redirecting...'
    },
  },
  methods: {
    emitInfoAlertOkClicked() {
      //console.log('emitInfoAlertOkClicked');
      this.$emit('emitInfoAlertOkClicked', this.emitMsg);
    }
  }
})
</script>

