

























































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import { LSS } from '@core/services';
import {
  SAVE_KA,
} from '../utils/apptweakqueries';
import InfoAlert from '../components/InfoAlert.vue';

export default Vue.extend({
  name: 'KochavaIntegration',
  components: {
    InfoAlert
  },
    data() {
        return {
            selectedApp : [],
            searchedApps : [],
            dialogapps: false,
            formText: 'valid',
            kaForm: 'kaform',
            //valid1 : false,
            //date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: [],
            modal: false,
            selectedapps: [],
            notEmpty: [
              (v) => v != '' || 'Please enter a value',
              (v) => v != 0 || 'Please enter a value',
              (v) => v != null || 'Please enter a value',
            ],
            vSelectRules: [(v) => v != null || 'Please select'],
            vDateRules: [(v) => (new Date(v) < new Date()) || 'Cannot select future date'],
            app_ids: [],
            api_keys: [],
            date: [],
            fullscreenLoader: null,
            infoAlertFlag: false,
            infoAlertTitle: 'Info',
            infoAlertMessage: '',
            validBtn : []
        };
    },
    methods: {
      goToSelectApps(){
        this.dialogapps = true
      },
      addApps(){
        this.dialogapps = false
        this.selectedApp.map(e => this.selectedapps.push(e))
        this.selectedapps.map(async (e,i) => {
          this.date[i] = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.menu[i] = false
          this.validBtn[i] = false
          this.getAppIcons(e.adamId, i)
        })
      },
      clearInfoAlert() {
        this.infoAlertFlag = false;
      },
      removeApp(app){
        this.selectedapps = this.selectedapps.filter(e => e.adamId !== app.adamId)
        LSS.kaIntApps = this.selectedapps
      },
      AddIntegration(app,i){
        const ref = ('kaform'+i.toString())
        const refVal = this.$refs[ref]
        refVal[0].validate()
        //console.log(refVal[0].validate())
        //this.validBtn[i] = refVal[0].validate()
        // if (!this['valid'+i]) {
        //   return;
        // }
        if(!this.app_ids || !this.app_ids[i]) return
        if(!this.api_keys || !this.api_keys[i]) return
        if(new Date(this.date[i]) > new Date()) return
        this.kochavaAttributionSave(app,i)
      },
      kochavaAttributionSave(app,i){
        this.fullscreenLoader = this.$loading.show()
        this.$apollo
        .query({
          query: SAVE_KA,
          variables: {
            api_key:  this.api_keys[i],
            app_guid: this.app_ids[i],
            apple_app_id: app.adamId,
            hydrate_date: this.date[i],
            type: 'kochava'
          }
        })
        .then((res) => {
          //console.log(res.data);
          this.fullscreenLoader.hide()
          if(res.data.kochavaAttributionSave.code == 1){
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Info!!!';
            this.infoAlertMessage = 'Attribution already exists';
            return
          }else if(res.data.kochavaAttributionSave.code == 2){
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Error!!!';
            this.infoAlertMessage = 'Invalid API key';
            return
          }else if(res.data.kochavaAttributionSave.code == 3){
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Error!!!';
            this.infoAlertMessage = 'App guid not found';
            return
          }else if(res.data.kochavaAttributionSave.code == 0){
            this.$notify.success(this.$t('successMsg.integrateSuccessMsg'));
            this.selectedapps = this.selectedapps.filter(e => e.adamId !== app.adamId)
            LSS.kaIntApps = this.selectedapps
          }else {
            this.infoAlertFlag = true;
            this.infoAlertTitle = 'Error!!!';
            this.infoAlertMessage = 'Server error. Please try later';
            return
          }
        })
        .catch((error) => {
          this.fullscreenLoader.hide()
        });
      },
    },
    mounted(){
      if(LSS.kaIntApps && LSS.kaIntApps.length>0){
        this.selectedapps = LSS.kaIntApps
        //LSS.kaIntApps = []
        this.selectedapps.map(async (e,i) => {
          this.date[i] = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.menu[i] = false
          this.validBtn[i] = false
          this.getAppIcons(e.adamId, i)
        })
      }
      if(LSS.ownApps && LSS.ownApps.length > 0) {
        const unAttrApps = LSS.ownApps.filter(e => !e.attrExist)
        const appsNotInUI = unAttrApps.filter(e => !this.selectedapps.map(x => x.adamId).includes(e.adamId))
        this.searchedApps = appsNotInUI
      }
    }
  });
