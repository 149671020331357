var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "300" },
          model: {
            value: _vm.InfoAlert,
            callback: function ($$v) {
              _vm.InfoAlert = $$v
            },
            expression: "InfoAlert",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "d-flex align-center justify-center flex-column" },
            [
              _c(
                "v-card-title",
                {
                  staticClass:
                    "d-flex justify-space-between align-center w-100 primary white--text px-6 py-3",
                },
                [_c("h5", [_vm._v(" " + _vm._s(_vm.InfoAlertTitle))])]
              ),
              _c(
                "v-card-text",
                { staticClass: "d-flex align-start px-6 py-6" },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "text-subtitle-1 pa-0",
                      staticStyle: { "word-break": "normal" },
                    },
                    [_vm._v(" " + _vm._s(_vm.InfoAlertMessage) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                {
                  staticClass:
                    "d-flex justify-end align-center w-100 pb-4 pt-0 px-6",
                },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.emitInfoAlertOkClicked },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("infoAlert.ok")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }