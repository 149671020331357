var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard dashboard-with-banner" },
    [
      _c("v-app", [
        _c(
          "div",
          { staticClass: "inner-container" },
          [
            _c(
              "div",
              {
                staticClass:
                  "top-page-header only-heading page-header-with-banner",
              },
              [
                _c("div", { staticClass: "head-title" }, [
                  _c("div", { staticClass: "top-heading-left" }, [
                    _c("h1", [_vm._v("Integration")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "top-heading-right" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            depressed: "",
                            dense: "",
                            dark: "",
                          },
                          on: { click: _vm.goToSelectApps },
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-2", attrs: { small: "" } },
                            [_vm._v("mdi-plus")]
                          ),
                          _vm._v(" Add More Apps "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _c(
              "v-container",
              {
                staticClass: "v-main-content only-simple-content",
                attrs: { fluid: "" },
              },
              [
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pa-4 rounded-lg app-integration-card",
                      attrs: { elevation: "3" },
                    },
                    [
                      _c("div", { staticClass: "title-box" }, [
                        _c("h2", [_vm._v("Apps Integration")]),
                        _c("p", [
                          _vm._v("Add App keys and API Id’s to selected app."),
                        ]),
                      ]),
                      _vm._l(_vm.selectedapps, function (app, i) {
                        return _c("div", { key: app.adamId }, [
                          _c(
                            "div",
                            { staticClass: "integrationList" },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-4 rounded-lg",
                                  attrs: { elevation: "0" },
                                },
                                [
                                  _c("v-card-title", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grey--text integration-head-left",
                                      },
                                      [
                                        _c("v-img", {
                                          attrs: { width: "60", src: app.icon },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "black--text ml-4" },
                                          [_vm._v(" " + _vm._s(app.appName))]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "grey--text ml-4" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "error",
                                              text: "",
                                              small: "",
                                              depressed: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeApp(app)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { staticClass: "error--text" },
                                              [_vm._v("mdi-delete-outline")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-form",
                                        {
                                          ref: "" + _vm.kaForm + i,
                                          refInFor: true,
                                          attrs: {
                                            "v-model": "" + _vm.formText + i,
                                          },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { align: "center" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    lg: "3",
                                                    md: "3",
                                                    sm: "12",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "black--text mb-3 d-flex",
                                                    },
                                                    [_vm._v("Add API Key:")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    lg: "6",
                                                    md: "6",
                                                    sm: "12",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "field" },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: "Add API Key",
                                                          outlined: "",
                                                          dense: "",
                                                          rules: _vm.notEmpty,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.api_keys[i],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.api_keys,
                                                              i,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "api_keys[i]",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { align: "center" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    lg: "3",
                                                    md: "3",
                                                    sm: "12",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "black--text mb-3 d-flex",
                                                    },
                                                    [_vm._v("Add App GUId:")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    lg: "6",
                                                    md: "6",
                                                    sm: "12",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "field" },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: "Add API GUID",
                                                          outlined: "",
                                                          dense: "",
                                                          rules: _vm.notEmpty,
                                                        },
                                                        model: {
                                                          value: _vm.app_ids[i],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.app_ids,
                                                              i,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "app_ids[i]",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { align: "center" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    lg: "3",
                                                    md: "3",
                                                    sm: "12",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "black--text mb-3 d-flex",
                                                    },
                                                    [_vm._v("Hydate Date:")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    lg: "6",
                                                    md: "6",
                                                    sm: "12",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "field" },
                                                    [
                                                      _c(
                                                        "v-menu",
                                                        {
                                                          ref: "menu",
                                                          refInFor: true,
                                                          attrs: {
                                                            "close-on-content-click": false,
                                                            "return-value":
                                                              _vm.date[i],
                                                            transition:
                                                              "scale-transition",
                                                            "offset-y": "",
                                                            "min-width": "auto",
                                                          },
                                                          on: {
                                                            "update:returnValue": function (
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                _vm.date,
                                                                i,
                                                                $event
                                                              )
                                                            },
                                                            "update:return-value": function (
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                _vm.date,
                                                                i,
                                                                $event
                                                              )
                                                            },
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-text-field",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            attrs: {
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              label:
                                                                                "Picker in menu",
                                                                              "append-icon":
                                                                                "mdi-calendar",
                                                                              readonlya:
                                                                                "",
                                                                              rules:
                                                                                _vm.vDateRules,
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                _vm
                                                                                  .date[
                                                                                  i
                                                                                ],
                                                                              callback: function (
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  _vm.date,
                                                                                  i,
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "date[i]",
                                                                            },
                                                                          },
                                                                          "v-text-field",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      )
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                          model: {
                                                            value: _vm.menu[i],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.menu,
                                                                i,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "menu[i]",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-date-picker",
                                                            {
                                                              attrs: {
                                                                "no-title": "",
                                                                scrollable: "",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.date[i],
                                                                callback: function (
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.date,
                                                                    i,
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "date[i]",
                                                              },
                                                            },
                                                            [
                                                              _c("v-spacer"),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    text: "",
                                                                    color:
                                                                      "primary",
                                                                  },
                                                                  on: {
                                                                    click: function (
                                                                      $event
                                                                    ) {
                                                                      _vm.menu[
                                                                        i
                                                                      ] = false
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Cancel "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    text: "",
                                                                    color:
                                                                      "primary",
                                                                  },
                                                                  on: {
                                                                    click: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$refs.menu[
                                                                        i
                                                                      ].save(
                                                                        _vm
                                                                          .date[
                                                                          i
                                                                        ]
                                                                      )
                                                                    },
                                                                  },
                                                                },
                                                                [_vm._v(" OK ")]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { align: "center" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    lg: "3",
                                                    md: "3",
                                                    sm: "12",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "buttons-col",
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            depressed: "",
                                                            dense: "",
                                                            dark: "",
                                                            disabled:
                                                              _vm.validBtn[i],
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.AddIntegration(
                                                                app,
                                                                i
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Integrate ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      }),
                    ],
                    2
                  ),
                  _c(
                    "v-dialog",
                    {
                      attrs: { "max-width": "600px", persistent: "" },
                      model: {
                        value: _vm.dialogapps,
                        callback: function ($$v) {
                          _vm.dialogapps = $$v
                        },
                        expression: "dialogapps",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "integrationDialogform" },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass:
                                "headline grey lighten-5 v-modal-header d-flex justify-space-between px-5 py-3 mb-5",
                            },
                            [
                              _c("h5", { staticClass: "text-h6" }, [
                                _vm._v("Select Apps"),
                              ]),
                              _c(
                                "v-icon",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.dialogapps = false
                                    },
                                  },
                                },
                                [_vm._v("mdi-close")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              items: _vm.searchedApps,
                                              label: "Select Apps",
                                              "item-text": "appName",
                                              "item-value": "adamId",
                                              "return-object": "",
                                              rules: _vm.vSelectRules,
                                              multiple: "",
                                            },
                                            model: {
                                              value: _vm.selectedApp,
                                              callback: function ($$v) {
                                                _vm.selectedApp = $$v
                                              },
                                              expression: "selectedApp",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            { staticClass: "pa-8" },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "vcancel",
                                  attrs: {
                                    color: "default darken-1",
                                    large: "",
                                    outlined: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.dialogapps = false
                                    },
                                  },
                                },
                                [_vm._v(" Cancel ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "vselect",
                                  attrs: {
                                    color: "primary darken-1",
                                    large: "",
                                    depressed: "",
                                  },
                                  on: { click: _vm.addApps },
                                },
                                [_vm._v(" Select ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
            _c("InfoAlert", {
              attrs: {
                InfoAlert: _vm.infoAlertFlag,
                InfoAlertTitle: _vm.infoAlertTitle,
                InfoAlertMessage: _vm.infoAlertMessage,
              },
              on: { emitInfoAlertOkClicked: _vm.clearInfoAlert },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }